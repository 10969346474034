/* eslint-disable no-console */
import type {
  IAnalyticsAdapter,
  AnalyticAuthMethod,
  AnalyticUserProps,
} from '@/shared/lib/analytics'

import type { GtagGAPurchase, GtagGAPurchaseItem } from './googleAnalytics.types'
import { gtag } from './gtag.public'

export class GoogleAnalyticsAdapter implements IAnalyticsAdapter {
  protected _checkAccess: () => boolean
  protected _isInit: boolean = false
  protected _logging: boolean = false
  protected _seq: number = 0
  protected _windowId: string | undefined
  protected _deviceId: string | undefined
  protected _measurementId: string | undefined

  constructor(
    checkAccess: () => boolean,
    logging: boolean,
    windowId: string,
    deviceId: string,
    measurementId: string,
  ) {
    this._checkAccess = checkAccess
    this._logging = logging
    this._windowId = windowId
    this._deviceId = deviceId
    this._measurementId = measurementId
  }

  init(): void {
    if (!this._measurementId || !this._checkAccess()) return
    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=${this._measurementId}`
    document.head.appendChild(script)

    gtag('config', this._measurementId, {
      debug_mode: false,
      send_page_view: false,
    })
    gtag('set', 'user_properties', {
      deviceId: this._deviceId,
    })

    this._isInit = true
  }

  public login(method: AnalyticAuthMethod): void {
    gtag('event', 'login', { method })
  }

  public signUp(method: AnalyticAuthMethod): void {
    gtag('event', 'sign_up', { method })
  }

  public userId(userId: string): void {
    if (!this._isInit) return
    gtag('set', 'user_properties', { userId })
    gtag('set', 'user_properties', { customer_id: userId })
  }

  public userProps(props: AnalyticUserProps): void {
    if (!this._isInit) return
    gtag('set', 'user_properties', props)
  }

  public event(event: string, data?: AnalyticUserProps): void {
    if (!this._isInit) return
    const googleAnalyticsData = Object.assign({}, data, {
      window: this._windowId,
      windowSeq: this._seq++,
    })
    gtag('event', event, googleAnalyticsData)
    if (this._logging) {
      console.log('GoogleAnalytics event: %s', event, googleAnalyticsData, 'color: orange;')
    }
  }

  public flush() {}

  /**
   * @link https://developers.google.com/gtagjs/reference/ga4-events#purchase
   */
  public purchase(props: GtagGAPurchase, items: GtagGAPurchaseItem[]): void {
    gtag('event', 'purchase', {
      ...props,
      items,
    })
  }
}
