export const env = Object.freeze({
  PROJECT_NAME: import.meta.env.PROJECT_NAME,
  VERSION: import.meta.env.VERSION,
  NODE_ENV: import.meta.env.NODE_ENV,
  APP_ENV: import.meta.env.APP_ENV,
  IS_DEV: import.meta.env.APP_ENV === 'development',
  IS_STAGE: import.meta.env.APP_ENV === 'stage',
  IS_PROD: import.meta.env.APP_ENV === 'production',
  AUTH_URL: import.meta.env.AUTH_URL,
  APP_URL: import.meta.env.APP_URL,
  TRACK_URL: import.meta.env.TRACK_URL,
  SOCKET_URL: import.meta.env.SOCKET_URL || '',
  LOG_URL: import.meta.env.LOG_URL || '',
  APPLICATION: import.meta.env.APPLICATION,
  VERSION_BACKEND: import.meta.env.VERSION_BACKEND || '0',

  GOOGLE_MEASUREMENT_ID: import.meta.env.GOOGLE_MEASUREMENT_ID,
  GOOGLE_OAUTH_CLIENT_ID: import.meta.env.GOOGLE_OAUTH_CLIENT_ID,

  SENTRY_DNS: import.meta.env.SENTRY_DNS || '',
  SENTRY_AUTH_TOKEN: import.meta.env.SENTRY_AUTH_TOKEN || '',
  SENTRY_ORG: import.meta.env.SENTRY_ORG || '',
  SENTRY_PROJECT: import.meta.env.PROJECT_NAME,
  SENTRY_TRACE_HOSTS: import.meta.env.SENTRY_TRACE_HOSTS,

  FACEBOOK_PIXEL_ID: import.meta.env.FACEBOOK_PIXEL_ID,
  FACEBOOK_TEST_CODE: import.meta.env.FACEBOOK_TEST_CODE,

  AMPLITUDE_API_KEY: import.meta.env.AMPLITUDE_API_KEY,

  FIREBASE_API_KEY: import.meta.env.FIREBASE_API_KEY || '',
  FIREBASE_AUTH_DOMAIN: import.meta.env.FIREBASE_AUTH_DOMAIN || '',
  FIREBASE_PROJECT_ID: import.meta.env.FIREBASE_PROJECT_ID || '',
  FIREBASE_STORAGE_BUCKET: import.meta.env.FIREBASE_STORAGE_BUCKET || '',
  FIREBASE_MESSAGE_SENDER_ID: import.meta.env.FIREBASE_MESSAGE_SENDER_ID || '',
  FIREBASE_APP_ID: import.meta.env.FIREBASE_APP_ID || '',
  FIREBASE_MESSAGE_VIPED_KEY: import.meta.env.FIREBASE_MESSAGE_VIPED_KEY || '',
  FIREBASE_MEASUREMENT_ID: import.meta.env.FIREBASE_MEASUREMENT_ID || '',
})
